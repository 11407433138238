var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var activeTabClassName = 'tab-link--active';
function init(tabsParent) {
    tabsParent.querySelectorAll('.tab-link').forEach(function (el) {
        el.addEventListener('click', function (e) {
            e.preventDefault();
            onClick(el);
            history.pushState(el.innerText, "".concat(el.innerText, " \u0431\u0440\u0435\u043D\u0434\u044B"), el.href);
        });
    });
    window.addEventListener('popstate', function (event) { return activateByTitle(event.state); });
    document.addEventListener('DOMContentLoaded', activateActiveElement);
    function activateByTitle(title) {
        var e_1, _a;
        if (document.location.hash && title === null) {
            return;
        }
        if (title === null) {
            title = 'Все';
        }
        try {
            for (var _b = __values(Array.from(tabsParent.querySelectorAll('.tab-link'))), _c = _b.next(); !_c.done; _c = _b.next()) {
                var el = _c.value;
                if (el.innerText !== title) {
                    continue;
                }
                onClick(el);
                break;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    function activateActiveElement() {
        var e_2, _a;
        try {
            for (var _b = __values(Array.from(tabsParent.querySelectorAll('.tab-link'))), _c = _b.next(); !_c.done; _c = _b.next()) {
                var el = _c.value;
                if (!el.classList.contains(activeTabClassName)) {
                    continue;
                }
                onClick(el, true);
                break;
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
    }
    function onClick(el, force) {
        if (force === void 0) { force = false; }
        if (el.classList.contains(activeTabClassName) && !force) {
            return;
        }
        tabsParent.querySelectorAll(".".concat(activeTabClassName))
            .forEach(function (el) { return el.classList.remove(activeTabClassName); });
        el.classList.add(activeTabClassName);
        if (el.innerText === 'Все') {
            reset();
        }
        else {
            updateCategory(el.innerText);
        }
    }
    function updateCategory(category) {
        var e_3, _a, e_4, _b;
        try {
            for (var _c = __values(Array.from(document.querySelectorAll(".abc-nav__item:not([data-category~=\"".concat(category, "\"]):not(.hidden)")))), _d = _c.next(); !_d.done; _d = _c.next()) {
                var el = _d.value;
                el.classList.add('hidden');
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_3) throw e_3.error; }
        }
        try {
            for (var _e = __values(Array.from(document.querySelectorAll(".abc-nav__item.hidden[data-category~=\"".concat(category, "\"]")))), _f = _e.next(); !_f.done; _f = _e.next()) {
                var el = _f.value;
                el.classList.remove('hidden');
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
            }
            finally { if (e_4) throw e_4.error; }
        }
        updateLettersVisibility();
    }
    function reset() {
        var e_5, _a;
        try {
            for (var _b = __values(Array.from(document.querySelectorAll('.abc-nav__item.hidden'))), _c = _b.next(); !_c.done; _c = _b.next()) {
                var el = _c.value;
                el.classList.remove('hidden');
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_5) throw e_5.error; }
        }
        updateLettersVisibility();
    }
    function updateLettersVisibility() {
        var e_6, _a;
        try {
            for (var _b = __values(Array.from(document.querySelectorAll('.abc-nav__block'))), _c = _b.next(); !_c.done; _c = _b.next()) {
                var letterBlock = _c.value;
                var letter = letterBlock.querySelector('.abc-nav__letter').innerText.trim();
                var letterLink = document.querySelector(".letters-nav__link[href=\"#".concat(letter, "\"]"));
                if (letterBlock.querySelector('.abc-nav__item:not(.hidden)')) {
                    letterBlock.classList.remove('hidden');
                    letterLink.classList.remove('disabled');
                }
                else {
                    letterBlock.classList.add('hidden');
                    letterLink.classList.add('disabled');
                }
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_6) throw e_6.error; }
        }
    }
}
var filter = document.querySelector('.abc-nav__filter');
if (filter) {
    init(filter);
}
