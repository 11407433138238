import $ from '../vendor/dom7';
var delay = 400;
var offset = 30;
export default function initLetterNavigation() {
    if (!$('.abc-nav').length) {
        return;
    }
    $('.abc-nav__head').on('click', function () {
        var $this = $(this);
        var $body = $this.next();
        if (!isMobile()) {
            return;
        }
        if ($this.next().hasClass('open')) {
            $this.removeClass('open');
            $body.removeClass('open');
        }
        else {
            open($this);
        }
        if (!$this.length) {
            return;
        }
        setTimeout(fixScrollPosition, delay);
    });
    window.addEventListener('hashchange', focusLetter);
    document.addEventListener('DOMContentLoaded', focusLetter);
}
function fixScrollPosition() {
    var $item = $('.abc-nav__head.open');
    if (!$item.length) {
        return;
    }
    var rect = $item.parent()[0].getBoundingClientRect();
    if (rect.top > (0 - offset) && rect.bottom <= window.innerHeight) {
        return;
    }
    window.scrollBy({
        behavior: 'smooth',
        top: rect.top + offset,
    });
}
function isMobile() {
    return window.innerWidth < 768;
}
function focusLetter() {
    if (!document.location.hash || !isMobile()) {
        return;
    }
    var link = document.querySelector(decodeURIComponent(document.location.hash));
    if (!link) {
        return;
    }
    var $item = $(link).next();
    if (!$item.hasClass('open')) {
        open($item);
    }
    fixScrollPosition();
}
function open($item) {
    var $body = $item.next();
    updateItemHeight($body[0]);
    $('.abc-nav').find('.abc-nav__head.open, .abc-nav__body.open').removeClass('open');
    $item.addClass('open');
    $body.addClass('open');
}
function updateItemHeight(item) {
    var height = Math.ceil(item.firstElementChild.getBoundingClientRect().height);
    item.style.setProperty('--height', "".concat(height, "px"));
}
window.addEventListener('resize', function () {
    var $body = $('.abc-nav__body.open');
    if (!$body.length || !isMobile()) {
        return;
    }
    updateItemHeight($body[0]);
});
