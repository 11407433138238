var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { addLiveEventListener, triggerEvent, triggerProductsLoaded } from '../utils';
import { hidePreloader, showPreloader } from "./preloader";
import scrollToElement from "./scrollto";
if (document.querySelector('.pager[data-ajax="true"]')) {
    addLiveEventListener(document.body, 'click', '.pager__single a, .pager-link', onClick);
    history.replaceState(buildState(), document.title);
    fixViewport();
    fixViewport(300);
    window.addEventListener('popstate', onPopState);
}
function onClick(event) {
    return __awaiter(this, void 0, void 0, function () {
        var pagerElement, currentPage, direct, filter, url, page, isMoreButton, params, html;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    pagerElement = document.querySelector('.pager');
                    if ((pagerElement === null || pagerElement === void 0 ? void 0 : pagerElement.dataset.ajax) !== 'true') {
                        return [2 /*return*/];
                    }
                    currentPage = parseInt(pagerElement.dataset.currentPage || '1');
                    direct = pagerElement.dataset.direct === 'true';
                    filter = pagerElement.dataset.filter === 'true';
                    url = direct && pagerElement.dataset.baseUrl ? pagerElement.dataset.baseUrl : '/ajax/catalog_page.php';
                    isMoreButton = event.target.matches('.pager__single a');
                    showPreloader();
                    event.preventDefault();
                    if (isMoreButton) {
                        page = currentPage + 1;
                    }
                    else if (event.target.dataset.page) {
                        page = parseInt(event.target.dataset.page);
                    }
                    else {
                        page = parseInt(event.target.innerText.trim());
                    }
                    if (filter) {
                        triggerEvent(document, 'filter:page', { page: page, isMoreButton: isMoreButton });
                        return [2 /*return*/];
                    }
                    params = direct ? {} : window.filterStatus;
                    return [4 /*yield*/, fetch(url, {
                            method: 'POST',
                            credentials: 'same-origin',
                            body: getFormData(page, params)
                        }).then(function (response) { return response.text(); })
                            .catch(function (error) {
                            hidePreloader();
                            window.Sentry.captureException(error);
                        })];
                case 1:
                    html = _a.sent();
                    if (html) {
                        updateHtml(html, !isMoreButton, undefined, isMoreButton);
                        pushState(html, page);
                    }
                    return [2 /*return*/];
            }
        });
    });
}
function onPopState(event) {
    if (!event.state || !event.state.html) {
        return;
    }
    updateHtml(event.state.html, false, event.state.pager);
    fixViewport();
}
function fixViewport(delay) {
    if (delay === void 0) { delay = 0; }
    setTimeout(function () {
        var catalogItems = document.querySelector('.catalog_items');
        var boundingRect = catalogItems.getBoundingClientRect();
        if (boundingRect.bottom < 300) {
            scrollToElement(catalogItems, -20);
        }
    }, delay);
}
function pushState(html, page) {
    var query = new URLSearchParams(document.location.search);
    query.delete('page');
    if (page > 1) {
        query.append('page', page.toString());
    }
    history.pushState(buildState(html), document.title, query.toString() ? "?".concat(query.toString()) : './');
}
function buildState(html) {
    var _a, _b, _c;
    return {
        html: html ? html : (_a = document.querySelector('.catalog_items')) === null || _a === void 0 ? void 0 : _a.innerHTML.trim(),
        pager: html ? undefined : (_c = (_b = document.querySelector('.pager')) === null || _b === void 0 ? void 0 : _b.parentElement) === null || _c === void 0 ? void 0 : _c.outerHTML.trim(),
    };
}
export function updateHtml(html, scroll, pagerHtml, append) {
    var catalogItems = document.querySelector('.catalog_items');
    if (!catalogItems) {
        return;
    }
    if (append) {
        catalogItems.insertAdjacentHTML('beforeend', html);
    }
    else {
        triggerEvent(document, 'products_unload');
        catalogItems.innerHTML = html;
    }
    updatePagerHtml(pagerHtml);
    if (scroll) {
        scrollToElement(catalogItems, -20);
    }
    triggerProductsLoaded();
    hidePreloader();
}
export function updatePagerHtml(pagerHtml) {
    var _a, _b;
    var catalogItems = document.querySelector('.catalog_items');
    var pager;
    if (!catalogItems) {
        return;
    }
    if (pagerHtml) {
        var el = document.createElement('div');
        el.innerHTML = pagerHtml.trim();
        pager = el.firstChild;
    }
    else {
        pager = (_a = document.querySelector('.pager')) === null || _a === void 0 ? void 0 : _a.parentElement;
        if (!pager) {
            return;
        }
        pager.remove();
        if (pagerHtml === '') {
            return;
        }
    }
    var wrap = (_b = catalogItems.parentElement) === null || _b === void 0 ? void 0 : _b.parentElement;
    wrap === null || wrap === void 0 ? void 0 : wrap.querySelectorAll('.pager').forEach(function (el) { var _a; return (_a = el.parentElement) === null || _a === void 0 ? void 0 : _a.remove(); });
    wrap === null || wrap === void 0 ? void 0 : wrap.appendChild(pager);
}
function getFormData(page, params) {
    var data = {
        page: page,
        filter: JSON.stringify(params),
    };
    var formData = new FormData();
    for (var name in data) {
        if (data.hasOwnProperty(name)) {
            formData.append(name, data[name]);
        }
    }
    return formData;
}
