var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import './components/sentry';
import countdown from "./components/countdown";
import '../style/style.scss';
import initJumpTopButton from './components/jump_top_button';
import initLazyLoad from './components/lazy';
import initLetterNavigation from './components/letter_navigation';
import './components/readmore';
import initSearchBar from './components/search_bar';
import './components/scrollto_link';
import { hidePreloader, showPreloader } from "./components/preloader";
import { triggerEvent, triggerProductsLoaded } from "./utils";
import './components/quantity_control';
import { updatePagerHtml } from "./components/pager";
import './screens/order/repeat';
import initRegisterBanner from './components/register_banner';
import initModal from "./components/modal";
import './components/brands_filter';
import $ from './vendor/dom7';
import ajax from "./components/ajax";
import initClickToCopy from "./components/click_to_copy";
document.addEventListener('DOMContentLoaded', function () {
    countdown(document.querySelector('.bnn__countdown'));
    // гамбургер с меню
    $('.btn-main').on('click', function (e) {
        var $nav = $('.h-nav');
        e.preventDefault();
        if ($nav.hasClass('open')) {
            $nav.removeClass('open').addClass('hidden-md-down');
            this.classList.remove('open');
        }
        else {
            $nav.removeClass('hidden-md-down');
            setTimeout(function () { return $nav.addClass('open'); });
            this.classList.add('open');
        }
    });
    // ajax табы
    $(document.body).on('click', '.ajax_tab', function (e) {
        e.preventDefault();
        var td = this;
        var $td = $(td);
        var md = $td.data('md');
        if ($td.is('.tab-link--active')) {
            return;
        }
        $('.ajax_tab').removeClass('tab-link--active');
        $td.addClass('tab-link--active');
        showPreloader();
        if (window.filterStatus !== undefined) {
            triggerEvent(document, 'tab:change', { code: md });
            return;
        }
        history.pushState('', '', md !== 'ALL' ? '?MD=' + md : './');
        ajax('/ajax/ajax_tab.php', {
            MD: md,
            id: $td.data('id'),
            type: $td.parents('.tab-nav').data('type'),
            MODE: $td.data('mode')
        })
            .then(function (response) { return response.text(); })
            .then(function (data) {
            var _a;
            var $element;
            $element = $('.ajax_tab_page');
            if (!$element.length) {
                $element = $('.catalog_items');
            }
            (_a = document.querySelector('.pager')) === null || _a === void 0 ? void 0 : _a.remove();
            $element.html(data);
            updatePagerHtml();
            triggerProductsLoaded();
            $(document).trigger('filter:load');
            highlightFavorites();
        })
            .finally(function () { return hidePreloader(); });
    });
    // сердечко на товарах
    $('body').on('click', '.to-favorite', function (e) {
        var id = parseInt($(this).data('id'));
        e.preventDefault();
        this.classList.toggle('active');
        if (window.fav.indexOf(id) !== -1) {
            $(this).removeClass('in').removeClass('active');
            delete window.fav[window.fav.indexOf(id)];
        }
        else {
            $(this).addClass('in').addClass('active');
            window.fav.push(id);
            window.vkEvent(process.env.VK_PRICE_LIST_ID, 'add_to_wishlist', { products: [{ id: id }] });
        }
        if (window.fav.length > 0) {
            $('.favorits-info').show();
            $('.favorits-info>.favorits-info__value').html(window.fav.length.toString());
        }
        else {
            $('.favorits-info').hide();
        }
        showPreloader();
        ajax('/ajax/favorite.php', { id: id })
            .finally(function () { return hidePreloader(); });
    });
    highlightFavorites();
});
// подсветка избранных сердечек
function highlightFavorites() {
    var e_1, _a;
    var count = 0;
    if (typeof window['fav'] === 'undefined') {
        return;
    }
    try {
        for (var _b = __values(window.fav), _c = _b.next(); !_c.done; _c = _b.next()) {
            var id = _c.value;
            if (id > 0) {
                $(".to-favorite[data-id=\"".concat(id, "\"]")).addClass('in active');
                count++;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    if (count) {
        $('.favorits-info').show();
        $('.favorits-info>.favorits-info__value').html(count.toString());
    }
    else {
        $('.favorits-info').hide();
    }
}
document.addEventListener('products_loaded', highlightFavorites);
initLazyLoad();
initModal();
initRegisterBanner();
initLetterNavigation();
initSearchBar();
initJumpTopButton();
initClickToCopy('.copy-click');
