var ITEM_SELECTOR = '.search-results-item';
var SearchResultKeys = /** @class */ (function () {
    function SearchResultKeys(input, results, itemSelector, onSelect) {
        if (itemSelector === void 0) { itemSelector = ITEM_SELECTOR; }
        this.input = input;
        this.results = results;
        this.itemSelector = itemSelector;
        this.onSelect = onSelect;
        input.addEventListener("keyup", this.isHandledKeyPressed.bind(this));
        input.addEventListener("keypress", this.isHandledKeyPressed.bind(this));
        input.addEventListener("keydown", this.onKeyPress.bind(this));
    }
    SearchResultKeys.prototype.isHandledKeyPressed = function (event) {
        this.validateSelection();
        if (!['ArrowDown', 'ArrowUp', 'Home', 'End', 'PageUp', 'PageDown', 'Enter', 'Escape'].includes(event.key)) {
            return false;
        }
        if (event.key === 'Enter') {
            if (this.getSelectedItem()) {
                event.preventDefault();
                event.stopPropagation();
            }
            this.onSelect(this.count() === 1 ? this.getFirstItem() : this.getSelectedItem());
            return false;
        }
        if (event.shiftKey || event.altKey || event.ctrlKey || event.metaKey) {
            return false;
        }
        if (this.isEmpty()) {
            return false;
        }
        event.preventDefault();
        event.stopPropagation();
        return true;
    };
    SearchResultKeys.prototype.onKeyPress = function (event) {
        if (!this.isHandledKeyPressed(event)) {
            return;
        }
        this.select(this.parseKey(this.getSelectedItem(), event.key));
    };
    SearchResultKeys.prototype.parseKey = function (item, key) {
        var _a, _b;
        switch (key) {
            case 'ArrowDown':
                return (_a = this.getNextItem()) !== null && _a !== void 0 ? _a : this.getLastItem();
            case 'ArrowUp':
                return (_b = this.getPreviousItem()) !== null && _b !== void 0 ? _b : this.getFirstItem();
            case 'Home':
                return this.getFirstItem();
            case 'End':
                return this.getLastItem();
            case 'PageUp':
                return SearchResultKeys.multistep(item !== null && item !== void 0 ? item : this.getLastItem(), this.calculateNumberOfLines(), function (item) { return item.previousElementSibling; });
            case 'PageDown':
                return SearchResultKeys.multistep(item !== null && item !== void 0 ? item : this.getFirstItem(), this.calculateNumberOfLines(), function (item) { return item.nextElementSibling; });
        }
    };
    SearchResultKeys.prototype.getSelectedItem = function () {
        return this.results.querySelector("".concat(this.itemSelector, "[data-selected]"));
    };
    SearchResultKeys.prototype.isEmpty = function () {
        return !this.getFirstItem();
    };
    SearchResultKeys.prototype.getFirstItem = function () {
        return this.results.querySelector("".concat(this.itemSelector, ":first-child"));
    };
    SearchResultKeys.prototype.getLastItem = function () {
        return this.results.querySelector("".concat(this.itemSelector, ":last-child"));
    };
    SearchResultKeys.prototype.getSelectedItems = function () {
        return this.results.querySelectorAll("".concat(this.itemSelector, "[data-selected]"));
    };
    SearchResultKeys.prototype.calculateNumberOfLines = function () {
        var item = this.getFirstItem();
        if (!item) {
            return 0;
        }
        return Math.floor(this.results.getBoundingClientRect().height / item.getBoundingClientRect().height);
    };
    SearchResultKeys.prototype.select = function (item) {
        this.lastSelected = item;
        if (!item) {
            return false;
        }
        this.deselect(item);
        item.dataset.selected = '';
        SearchResultKeys.scrollIntoView(item);
        return true;
    };
    SearchResultKeys.multistep = function (start, steps, callback) {
        var current = start;
        while (steps--) {
            var result = callback(current);
            if (!result) {
                return current;
            }
            current = result;
        }
        return current;
    };
    SearchResultKeys.scrollIntoView = function (item) {
        item.scrollIntoView({
            block: "nearest",
            behavior: 'smooth',
        });
    };
    SearchResultKeys.prototype.deselect = function (exclude) {
        var deselected = false;
        this.getSelectedItems().forEach(function (item) {
            if (item !== exclude) {
                delete item.dataset.selected;
            }
            else {
                deselected = true;
            }
        });
        return deselected;
    };
    SearchResultKeys.prototype.getNextItem = function () {
        var selected = this.getSelectedItem();
        return selected ? selected.nextElementSibling : this.getFirstItem();
    };
    SearchResultKeys.prototype.getPreviousItem = function () {
        var selected = this.getSelectedItem();
        return selected ? selected.previousElementSibling : this.getLastItem();
    };
    SearchResultKeys.prototype.count = function () {
        return this.results.querySelectorAll(this.itemSelector).length;
    };
    SearchResultKeys.prototype.validateSelection = function () {
        this.deselect(this.lastSelected);
        if (this.lastSelected) {
            SearchResultKeys.scrollIntoView(this.lastSelected);
        }
    };
    return SearchResultKeys;
}());
export default SearchResultKeys;
