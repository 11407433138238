export function onClick(parent) {
    var input = parent.querySelector('input');
    if (navigator.clipboard) {
        navigator.clipboard.writeText(input.value)
            .then(function () { return showClicked(parent); });
    }
    else {
        input.select();
        document.execCommand('copy');
        showClicked(parent);
    }
}
function showClicked(el) {
    el.classList.add('clicked');
    if (el.dataset.timeout) {
        clearTimeout(el.dataset.timeout);
    }
    el.dataset.timeout = setTimeout(function () {
        el.classList.remove('clicked');
        delete el.dataset.timeout;
    }, 4000).toString();
}
export default function initClickToCopy(selector) {
    document.querySelectorAll(selector)
        .forEach(function (copyBox) { return copyBox.addEventListener('click', function () { return onClick(copyBox); }); });
}
