var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
export default function initJumpTopButton() {
    if (!window.IntersectionObserver) {
        return;
    }
    var toTopButton = createElement();
    toTopButton.addEventListener('click', function (e) {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: "smooth" });
    });
    var observer = new IntersectionObserver(function (entries) {
        var e_1, _a;
        try {
            for (var entries_1 = __values(entries), entries_1_1 = entries_1.next(); !entries_1_1.done; entries_1_1 = entries_1.next()) {
                var entry = entries_1_1.value;
                if (entry.isIntersecting) {
                    toTopButton.classList.remove('show');
                }
                else {
                    toTopButton.classList.add('show');
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (entries_1_1 && !entries_1_1.done && (_a = entries_1.return)) _a.call(entries_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    });
    observer.observe(document.querySelector('.header'));
}
function createElement() {
    var button = document.createElement('button');
    button.classList.add('to-top');
    button.innerText = 'Наверх';
    document.body.appendChild(button);
    return button;
}
